import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import HeaderPage from './components/HeaderPage.vue';
  import BodyPage from './components/BodyPage.vue';
  import FooterPage from './components/FooterPage.vue';
  // import {navItems as items} from "@/utils/data"

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderPage),
    _createVNode(BodyPage),
    _createVNode(FooterPage)
  ], 64))
}
}

})